<template>
  <div class="detail">
    <div>
      <div class="nav">首页 > <span class="click" @click="goBack">{{ info.categoryName ? info.categoryName : '公告' }}</span> > {{ text }}</div>

      <div class="detailContent">
        <div class="title oneLine">{{ info.title }}</div>
        <div class="line2">
          <div>发布时间：{{ info.createTime }}</div>
          <div>发布人：{{ info.createrName }}</div>
          <div>来源：{{ info.pbName }}</div>
        </div>
        <div v-if="info.video" class="video">
          <video :controls="true" :autoplay="false" :src="$beforeUrl(info.video)" :poster="$beforeUrl(info.videoPoster)" />
        </div>
        <div class="rich" style="margin-top:50px" v-html="$setContent(info.context)" />
      </div>
      <div class="back center click" @click="goBack">返回栏目列表</div>
    </div>
  </div>
</template>

<script>
import { getUrlParams } from '@/utils/common.js'
export default {
  data() {
    return {
      info: {
        // 'title': '12',
        // 'createTime': '2021年10月26日',
        // 'createrName': '李嘉源',
        // 'pbName': '南山区支部',
        // 'pv': 2,
        // 'context': '<p>21</p>',
        // 'video': '',
        // 'videoPoster': '',
        // 'categoryId': 'cb7ee76da841431a90eca19fa03daa03',
        // 'categoryName': '时政要闻',
        // 'type': 1
      },
      param: {
        id: ''
      }
    }
  },
  computed: {
    text() {
      if (this.info.type == 1) {
        return '文章详情'
      } else {
        return '详情'
      }
    }
  },
  watch: {
    async $route(val) {
      this.param = val.query || {}
      this.getInfo()
    }
  },

  mounted() {
  },
  created() {
    this.param = getUrlParams()
    this.getInfo()
  },

  methods: {
    getInfo() {
      this.$parent.loading = true
      this.$store.dispatch('main/HomeGetNewsInfo', { id: this.param.id }).then(res => {
        if (res.code === 0) {
          this.info = res.data
          // this.getLeftData()
          this.$parent.loading = false
        }
      })
    },
    goBack() {
      if (this.info.type === 3) {
        this.$go('/')
        return
      }
      this.$router.push({ path: '/main', query: { type: this.info.type, id: this.info.categoryId }})
    },
    getSelected() {
      for (let index = 0; index < this.leftData.length; index++) {
        const item = this.leftData[index]
        if (item.id === this.info.categoryId) {
          return index
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .detail{
    .nav,.click{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .video{
      width: 830px;
      height: 466px;
      margin: 58px auto 50px auto;
      position: relative;
      video{
        width: 100%;
        height: 100%;
      }
    }
    .detailContent{
      margin-top: 19px;
      border: 1px solid #EEEEEE;
      padding: 31px 23px;
      margin-right: 24px;
      .title{
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .line2{
        display: flex;
        line-height: 14px;
        margin-top: 20px;
        div{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin-right: 20px;
        }
        &>div:nth-child(1){
          padding-right: 20px;
          border-right: 1px solid #999;

        }
        &>div:nth-child(2){
          padding-right: 20px;
          border-right: 1px solid #999;

        }
      }
    }
    .back{
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 32px;
      width: 120px;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      margin-top: 30px;

    }

  }

</style>
